<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
					<a-col :span="24">
						<p class="text">{{l('Issue_Config')}}</p>
					</a-col>
                    <a-col :span="24">
						<a-checkbox v-model="photo">
						    {{l('Must_upload_photo_before_closing')}}
						</a-checkbox>
                    </a-col>
					<a-col :span="24">
						<a-checkbox v-model="remark">
						    {{l('Fill_in_the_remark_before_closing_the_question')}}
						</a-checkbox>
					</a-col>
                    <a-col :span="24">
						<a-checkbox v-model="IsRequiredIssueResp">
						    {{l('WhetherThePersonResponsibleForTheQuestionIsMandatory')}}
						</a-checkbox>
					</a-col>
					<a-col :span="24" style="display: flex;">
						<p style="margin: auto 0;">{{l('Issue_progress_reminders') + '：'}}</p>
						<a-select :filterOption="filterOption"
							style="width: 150px;margin-left: 10px;" v-model="Progress"
							:placeholder="l('issueprogress.Progress')" @change="issueProgressChange" allow-clear>
							<a-select-option v-for="item in issueProgressList" :key="item.progress"
								:title="item.name">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
					
					<a-col :span="24" style="margin-top: 30px;">
						<p class="text">{{l('Audit_settings')}}</p>
					</a-col>
					<a-col :span="24">
						<a-checkbox v-model="IsIssueFeedbackButton">
						    {{l('Whether_to_enable_the_issue_feedback_button')}}
						</a-checkbox>
					</a-col>
					<a-col :span="24">
						<a-checkbox v-model="Isnonconformances">
						    {{l('Whether_nonconformances_are_enabled')}}
						</a-checkbox>
					</a-col>
					
                    <a-col :span="24" style="margin-top: 30px;">
                    	<p class="text">{{l('PictureSettings')}}</p>
                    </a-col>
                    <a-col :span="24">
                    	<a-checkbox v-model="IsEnableUploadPicture">
                    	    {{l('Whether_to_disable_image_upload')}}
                    	</a-checkbox>
                    </a-col>

					<a-col :span="24" style="margin-top: 15px;">
						<a-button :type="'primary'" @click="save()" v-if="isGranted('other_save')">
							<a-icon type="save" />
							<span>{{ l('Save') }}</span>
						</a-button>
					</a-col>
                </a-row>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        IssueServiceProxy,
        EHSServiceProxy,
		SystemConfigServiceProxy,
		IssueProgressServiceProxy
    } from '../../../shared/service-proxies';
    import {
        ModalHelper
    } from '@/shared/helpers';

    let _this;
    export default {
        name: "issue-config",
        mixins: [AppComponentBase],
        components: {
			
        },
        created() {
            _this = this;
            this.IssueServiceProxy = new IssueServiceProxy(this.$apiUrl, this.$api);
            this.EHSServiceProxy = new EHSServiceProxy(this.$apiUrl, this.$api);
			this.SystemConfigServiceProxy = new SystemConfigServiceProxy(this.$apiUrl, this.$api);
			this.IssueProgressServiceProxy = new IssueProgressServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
			this.getissueProgress();
			this.getData();
			this.getRemark();
			this.getIsRequiredIssueResp();
			this.getIsEnableUploadPicture();
			this.getIsIssueFeedbackButton();
			this.getIsnonconformances();
			this.getIsIssueNotificaationFeature();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
				isLoading: false,
				photo: false,
				remark: false,
				date: {},
				remarkDate: {},
                IsRequiredIssueResp:false,
                Privatekey:undefined,
				IsEnableUploadPicture: false,
				IsIssueFeedbackButton: false,
				Isnonconformances: false,
				Progress: undefined,
				issueProgressList: [],
            }
        },
        methods: {
			getData() {
			    this.isLoading = true;
			    this.IssueServiceProxy.getIsMustUploadImprovedPicture(
					
			    ).finally(() => {
			        this.isLoading = false;
			    }).then(res => {
					this.photo = res;
			    })
			},
			//获取关闭问题前为否填写备注
			getRemark() {
				this.isLoading = true;
				this.IssueServiceProxy.getIsCloseIssueFillInRemarks(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.remark = res;
				})
			},
			
			//获取进度值
			getissueProgress() {
				this.isLoading = true;
				this.IssueProgressServiceProxy.getPaged().finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.issueProgressList = res.items;
				})
			},

            //获取关闭问题前为否填写备注
			getIsRequiredIssueResp() {
				this.isLoading = true;
				this.IssueServiceProxy.getIsRequiredIssueResp(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsRequiredIssueResp = res;
				})
			},
			
			//获取是图片上传是否禁用
			getIsEnableUploadPicture() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getIsEnableUploadPicture(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsEnableUploadPicture = !res;
				})
			},
			
			//获取是否启用问题反馈按钮
			getIsIssueFeedbackButton() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getIsEnableAuditPointFeedback(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.IsIssueFeedbackButton = res;
				})
			},
			
			//获取是否启用不符合项
			getIsnonconformances() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getIsEnableAuditTaskDetailDeviationType(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.Isnonconformances = res;
				})
			},
			
			//是否启用带关闭问题通知
			getIsIssueNotificaationFeature() {
				this.isLoading = true;
				this.SystemConfigServiceProxy.getToBeClosedIssueNoticeProgress(
					
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					this.Progress = res;
				})
			},
			
			save() {
				//关闭前上传图片
				this.date.enable = this.photo;
				this.isLoading = true;
				this.IssueServiceProxy.isMustUploadImprovedPicture(
					this.date
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})
				
				//问题关闭前填写备注
				this.remarkDate.enable = this.remark;
				this.isLoading = true;
				this.IssueServiceProxy.isCloseIssueFillInRemarks(
					this.remarkDate
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})

                //问题责任人是否必填
				this.isLoading = true;
				this.IssueServiceProxy.isRequiredIssueResp(
					{Enable:this.IsRequiredIssueResp}
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})
				
				//是否禁用图片上传
				this.isLoading = true;
				this.SystemConfigServiceProxy.isEnableUploadPicture(
					{Enable: !this.IsEnableUploadPicture}
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})
				
				//是否启用待关闭问题通知功能
				this.isLoading = true;
				this.SystemConfigServiceProxy.toBeClosedIssueNoticeProgress(
					{Progress: this.Progress}
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})
				
				//是否启用问题反馈按钮
				this.isLoading = true;
				this.SystemConfigServiceProxy.isEnableAuditPointFeedback(
					{Enable: this.IsIssueFeedbackButton}
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})
				
				// 是否启用不符合项
				this.isLoading = true;
				this.SystemConfigServiceProxy.isEnableAuditTaskDetailDeviationType(
					{Enable: this.Isnonconformances}
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
				})

                this.$notification.success({
						description: undefined,
						message: this.l('SavedSuccessfully'),
					});
			},
			
			issueProgressChange(value) {
				this.Progress = value;
			},
			
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
				);
			},
        }
    }
</script>

<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
	.text {
		font-size: 18px;
		font-weight: bold;
	}
</style>
